import {createGeneralPageEventsAndComponents} from "../components/page-general";
import {createHomepageElements} from "../components/homepage-elements";
import { createPromotionBar } from "../components/promotion-bar";
import { expressQuerySelector, expressQuerySelectorAll } from "../common/html";
import { initShowMoreElements } from "../components/show-more-elements";
import { createFaq } from "../components/faq";

window.addEventListener('load', () => {
	// Promotion bar
	const promotionBar = expressQuerySelector<HTMLElement>(document, '.technical-promotion-bar', false);
	if (promotionBar)
		createPromotionBar(promotionBar);

	createGeneralPageEventsAndComponents();
	createHomepageElements();

	const faqs = expressQuerySelectorAll<HTMLElement>(document, '.technical-faq-container');
	faqs.forEach(faqEl => {
		createFaq(faqEl);

		const buttonEl = expressQuerySelector<HTMLElement>(faqEl, '.technical-showmore-button', false);
		if (buttonEl) {
			initShowMoreElements(faqEl, buttonEl, null, '.technical-showmore-faq', 0);
		}
	});
});
