﻿import { createClickableElement } from "./button";
import { expressEventListener, expressQuerySelector, expressQuerySelectorAll } from "../common/html";
import { compositeDisposable, IDisposable } from "../utils/disposable";
import { IIconResult } from "../utils/icons";
import { calculateAndSetHeightOfMobileNavigationLevel } from "../utils/mobile-menu";

export interface ICreateNavigationMobileDependencies {
	readonly getMobileMenu: (secondLevelIndex?: number, thirdLevelIndex?: number) => Promise<string>;
	readonly getIconsAsync: (ids: string[]) => Promise<IIconResult[]>;
	readonly onOpen?: () => void;
	readonly onClose?: () => void;
}

export interface ICreateNavigationMobileOptions {
	readonly mobileMenuEl: HTMLElement;
	readonly containerEl: HTMLElement;
	readonly menuButtonEl: HTMLElement;
	readonly menuContainerEl: HTMLElement;
	readonly currentLanguage: string | undefined;
}

export function initNavigationMobile(options: ICreateNavigationMobileOptions, deps: ICreateNavigationMobileDependencies) {
	let firstLevelMenuSelected: HTMLElement | undefined;
	let onDocumentClickDisposable: IDisposable;
	let onCloseClickDisposable: IDisposable;
	let onLanguageSelectorClickDisposable: IDisposable;
	let userContainerEl: HTMLElement;
	let closeContainerEl: HTMLElement;
	let languageSelectorEl: HTMLElement | undefined;
	let firstLevelMenuEls: HTMLElement[] = [];
	let secondLevelMenuEls: HTMLElement[] = [];
	let thirdLevelMenuEls: HTMLElement[] = [];

	let loaded = false;

	const {
		mobileMenuEl,
		containerEl,
		menuButtonEl,
		menuContainerEl,
		currentLanguage
	} = options;

	const {
		getMobileMenu,
		getIconsAsync,
		onOpen,
		onClose,
	} = deps;

	const contentPreLoaded = mobileMenuEl.innerHTML.replace(/^\s+|\s+$/, '') !== "";

	const closeMenu = () => {
		menuButtonEl.classList.remove("menu-is-open");
		menuContainerEl.classList.remove("menu-is-open");

		// reset to first level
		resetMenu();

		detachDocumentClick();
		if (onClose) onClose();

		return Promise.resolve();
	};

	const onCloseMenuClick = (e: Event) => {
		e.stopPropagation();
		closeMenu();
	};

	const onDocumentClick = (e: MouseEvent) => {
		if (!(e.target as HTMLElement).closest(".technical-mobile-navigation-hamburger-menu-container, .technical-mobile-navigation-hamburger-menu-button")) {
			closeMenu();
		}
	};

	const attachOnDocumentClick = () =>
	(
		onDocumentClickDisposable = compositeDisposable(
			[
				expressEventListener(document, 'click', onDocumentClick, true)
			]
		)
	);

	const detachDocumentClick = () => onDocumentClickDisposable && onDocumentClickDisposable.dispose();

	const loadIcons = (urls: string[]) => {
		const icons = {};
		const uniqueUrls = urls.filter((v, i) => urls.indexOf(v) === i);
		return getIconsAsync(uniqueUrls)
			.then(res => {
				res.forEach(icon => (icons[icon.url] = icon.svg));
				return icons;
			});
	};

	const initMenuItemsAndBehavior = () => {
		const firstLevelListEl = expressQuerySelector<HTMLElement>(mobileMenuEl, ".o-navigation-mobile__first-level-navigation__button-list", true);
		userContainerEl = expressQuerySelector<HTMLElement>(mobileMenuEl, ".technical-mobile-navigation-user-container", true);
		closeContainerEl = expressQuerySelector<HTMLElement>(mobileMenuEl, ".technical-mobile-navigation-close-container", true);
		languageSelectorEl = expressQuerySelector<HTMLElement>(mobileMenuEl, ".technical-language-selector") || undefined;
		firstLevelMenuEls = expressQuerySelectorAll<HTMLElement>(mobileMenuEl, ".technical-mobile-navigation-first-level-container");

		// set language
		if (languageSelectorEl) {
			const activeLanguageEl = expressQuerySelector(languageSelectorEl, ".a-language-selector__language-active");
			if (activeLanguageEl && currentLanguage) {
				const languageEl = expressQuerySelector(activeLanguageEl, ".a-language-selector__language");
				if (languageEl) {
					languageEl.innerHTML = currentLanguage;
				}
			}
		}

		const iconButtonEls = expressQuerySelectorAll(firstLevelListEl, '.technical-icon-svg')
			.filter(el => !!el.getAttribute('data-icon-svg'));

		if (iconButtonEls.length > 0) {
			loadIcons(
				iconButtonEls.map(el => el.getAttribute('data-icon-svg')!)
			)
				.then(
					res =>
						iconButtonEls.forEach(el => {
							const icon = res[el.getAttribute('data-icon-svg')!];
							if (icon) el.insertAdjacentHTML("beforeend", icon);
						})
				);
		}

		if (closeContainerEl) attachCloseMenuClick(closeContainerEl);
		if (languageSelectorEl) attachLanguageSelectorClick(languageSelectorEl);
		attachFirstMenuItemClick(firstLevelMenuEls);
	};

	const loadMobileMenu = () => {
		if (!loaded) {
			if (!contentPreLoaded) {
				return getMobileMenu()
					.then(res => {
						loaded = true;
						mobileMenuEl.insertAdjacentHTML('beforeend', res);
						initMenuItemsAndBehavior();
					});
			} else {
				loaded = true;
				initMenuItemsAndBehavior();
				return Promise.resolve();
			}
		} else {
			return Promise.resolve();
		}
	};

	const onButtonClick = () =>
		menuContainerEl.classList.contains("menu-is-open")
			? closeMenu()
			: openMenu();

	const openMenu = () => {
		if (onOpen) onOpen();
		return loadMobileMenu()
			.then(() => {
				attachOnDocumentClick();

				const firstLevelListEl = expressQuerySelector<HTMLElement>(mobileMenuEl, ".o-navigation-mobile__first-level-navigation__button-list", true);
				calculateAndSetHeightOfMobileNavigationLevel(firstLevelListEl);

				// Save height for later use in data attribute 'elementHeight'
				firstLevelListEl.dataset.elementHeight = firstLevelListEl.style.height || '';

				// only show mobile menu when whole thing is loaded / initialized
				menuButtonEl.classList.add("menu-is-open");
				menuContainerEl.classList.add("menu-is-open");
			});
	};

	const resetMenu = () => {
		containerEl.classList.remove("second-level-is-active");
		containerEl.classList.remove("third-level-is-active");
		userContainerEl.classList.remove("u-hide");
		closeContainerEl.classList.remove("u-hide");

		// Show first level
		firstLevelMenuEls.forEach(firstLevelEl => {
			firstLevelEl.classList.remove("u-hide");
			const firstLevelMenuButtons = expressQuerySelectorAll(firstLevelEl, ".o-navigation-mobile__first-level-navigation__button");
			firstLevelMenuButtons.forEach(el => el.classList.remove("u-hide"));
		});

		//Hide second and third level
		expressQuerySelectorAll(containerEl, ".technical-mobile-navigation-second-level").forEach(el => {
			if (!el.classList.contains("u-hide")) el.classList.add("u-hide");
		});

		secondLevelMenuEls.forEach(secondLevelEl => {
			if (!secondLevelEl.classList.contains("u-hide")) secondLevelEl.classList.add("u-hide");

			const menuSecondLevelContainerEls = expressQuerySelectorAll(secondLevelEl, ".technical-mobile-navigation-container");
			menuSecondLevelContainerEls.forEach(el => el.classList.remove("u-hide"));

			const thirdLevelEls = expressQuerySelectorAll(secondLevelEl, ".technical-mobile-navigation-third-level");
			if (thirdLevelEls)
				thirdLevelEls.forEach(thirdLevelEl => {
					if (!thirdLevelEl.classList.contains("u-hide")) thirdLevelEl.classList.add("u-hide");
				});
		});
	};

	const hideOtherMenuItems = (elem: HTMLElement) => {
		if (elem.parentNode && elem.parentNode.hasChildNodes()) {
			[].slice.call(elem.parentNode.children)
				.forEach(el => {
					const elm: HTMLElement = el;
					if (elem !== elm) {
						elm.classList.toggle("u-hide");
					}
				});
		}
	};

	const onFirstMenuItemClick = (e: Event) => {
		if (handleHeaderLink(e.target)) return;
		const firstLevelEl = e.currentTarget as HTMLElement;
		const secondLevelIndex = Number(firstLevelEl.dataset.index) ?? -1;
		const secondLevelEl = expressQuerySelector(firstLevelEl, '.technical-mobile-navigation-second-level', false);
		if (!secondLevelEl) return; // no second level

		firstLevelMenuSelected = expressQuerySelector<HTMLElement>(firstLevelEl, ".technical-mobile-navigation-second-level") || undefined;
		const prevClickedEl = expressQuerySelector<HTMLElement>(mobileMenuEl, ".technical-clicked", false);

		// Values of first lvl menu (needs to be recalculated since the 'account' section isn't shown in 2nd lvl)
		const firstLevelListEl = expressQuerySelector<HTMLElement>(mobileMenuEl, ".o-navigation-mobile__first-level-navigation__button-list", true);
		// calculateAndSetHeightOfMobileNavigationLevel(firstLevelListEl);

		// Set height of first lvl again correct (get from data attribute elementHeight)
		firstLevelListEl.style.height = '100vh';

		// Reset scroll position otherwise 2nd lvl can already be scrolled down:
		firstLevelListEl.scrollTop = 0;


		if (firstLevelMenuSelected) {
			// Keep track of clicked element and its scrollTop
			if (!prevClickedEl) {
				firstLevelEl.classList.add("technical-clicked");
				firstLevelEl.dataset.offset = firstLevelEl.offsetTop + "";
				const parent = firstLevelEl.parentNode as HTMLElement;
				if (parent) parent.dataset.offset = parent.offsetTop + "";
			}

			containerEl.classList.add("second-level-is-active");
			containerEl.classList.remove("third-level-is-active");
			userContainerEl.classList.toggle("u-hide");
			closeContainerEl.classList.toggle("u-hide");

			hideOtherMenuItems(firstLevelEl);

			firstLevelMenuSelected.classList.toggle("u-hide");
			const firstLevelMenuButton = expressQuerySelector(firstLevelEl, ".o-navigation-mobile__first-level-navigation__button");
			if (firstLevelMenuButton) firstLevelMenuButton.classList.toggle("u-hide");

			const secondLevelNavigationEl = expressQuerySelector(firstLevelMenuSelected, ".technical-second-level");
			if (secondLevelNavigationEl) secondLevelNavigationEl.classList.remove("u-hide");

			const secondLevelContainerEl = expressQuerySelectorAll(firstLevelMenuSelected, ".technical-mobile-navigation-second-level-container");
			secondLevelContainerEl.forEach((el) => el.classList.remove("u-hide"));
		}

		// In case we have a clicked element bring it into view
		if (prevClickedEl) {
			prevClickedEl.classList.remove("technical-clicked");
			setScrollTop(prevClickedEl);
			if (prevClickedEl.parentNode) {
				setScrollTop(prevClickedEl.parentNode as HTMLElement);
			}
		}

		const loadSecondLevelEl = expressQuerySelector(secondLevelEl, '.technical-replace-level-loaded', false);
		if (!loadSecondLevelEl || !loadSecondLevelEl.parentElement) {
			// Calculate height of second lvl menu (without browser bars)
			const menuItemsSecondLvl = expressQuerySelector<HTMLElement>(firstLevelMenuSelected, '.o-navigation-mobile__second-level-navigation__button-list', false);
			if (menuItemsSecondLvl && menuItemsSecondLvl.clientHeight > 0)
				calculateAndSetHeightOfMobileNavigationLevel(menuItemsSecondLvl);
			else
				firstLevelListEl.style.height = firstLevelListEl.dataset.elementHeight || '';

			return;
		}

		// load second level
		getMobileMenu(secondLevelIndex, -1).then(res => {
			const frag = document.createRange().createContextualFragment(res);
			secondLevelEl.replaceChild(frag, loadSecondLevelEl);
			const secondLevelItems = expressQuerySelectorAll<HTMLElement>(firstLevelEl, ".technical-mobile-navigation-second-level-container");
			secondLevelMenuEls = secondLevelMenuEls.concat(secondLevelItems);
			attachSecondMenuItemClick(secondLevelItems);

			// Calculate height of second lvl menu (without browser bars)
			const menuItemsSecondLvl = expressQuerySelector<HTMLElement>(firstLevelMenuSelected, '.o-navigation-mobile__second-level-navigation__button-list', false);
			if (menuItemsSecondLvl && menuItemsSecondLvl.clientHeight > 0)
				calculateAndSetHeightOfMobileNavigationLevel(menuItemsSecondLvl);
			else
				firstLevelListEl.style.height = firstLevelListEl.dataset.elementHeight || '';
		});
	};

	const setScrollTop = (el: HTMLElement) => {
		const scrollPos = el.dataset.offset || "";
		if (scrollPos && el.parentNode) {
			const parent = el.parentNode as HTMLElement;
			parent.scrollTop = parseInt(scrollPos, 10);
			el.dataset.offset = "";
		}
	};

	const onSecondMenuItemClick = (e: Event) => {
		if (handleHeaderLink(e.target)) return;
		e.stopPropagation();
		const secondLevelEl = e.currentTarget as HTMLElement;

		const thirdLevelDivEl = expressQuerySelector(secondLevelEl, '.technical-mobile-navigation-third-level', false);
		if (!thirdLevelDivEl) return; // no third level

		// 3th level
		const thirdLevelEl = secondLevelEl.querySelector(".technical-mobile-navigation-third-level");
		if (thirdLevelEl) {
			containerEl.classList.toggle("third-level-is-active");
			thirdLevelEl.classList.toggle("u-hide");

			hideOtherMenuItems(secondLevelEl);

			if (firstLevelMenuSelected) {
				const secondeLevelNavigationEl = firstLevelMenuSelected.querySelector(".technical-second-level");
				if (secondeLevelNavigationEl) secondeLevelNavigationEl.classList.toggle("u-hide");
			}

			const thirdLevelContainerEl = secondLevelEl.querySelector(".technical-mobile-navigation-container");
			if (thirdLevelContainerEl) thirdLevelContainerEl.classList.toggle("u-hide");
		}

		const loadThridLevelEl = expressQuerySelector(thirdLevelDivEl, '.technical-replace-level-loaded', false);
		if (!loadThridLevelEl || !loadThridLevelEl.parentElement) {
			const menuItemsThirdLvl = expressQuerySelector<HTMLElement>(secondLevelEl, '.o-navigation-mobile__third-level-navigation__button-list', false);
			// Calculate height of third lvl menu (without browser bars)
			if (menuItemsThirdLvl && menuItemsThirdLvl.offsetHeight > 0)
				calculateAndSetHeightOfMobileNavigationLevel(menuItemsThirdLvl);

			return;
		}

		// get from api
		const secondLevelIndex = Number((secondLevelEl.closest('.technical-mobile-navigation-first-level-container') as HTMLElement).dataset.index) ?? -1;
		const thirdLevelIndex = Number(secondLevelEl.dataset.index) ?? -1;
		getMobileMenu(secondLevelIndex, thirdLevelIndex).then(res => {
			const frag = document.createRange().createContextualFragment(res);
			thirdLevelDivEl.replaceChild(frag, loadThridLevelEl); // sub level of level
			const thirdLevelItems = expressQuerySelectorAll<HTMLElement>(secondLevelEl, ".o-navigation-mobile__third-level-navigation__button-container");
			thirdLevelMenuEls = thirdLevelMenuEls.concat(thirdLevelItems);
			attachThirdMenuItemClick(thirdLevelItems);

			const menuItemsThirdLvl = expressQuerySelector<HTMLElement>(secondLevelEl, '.o-navigation-mobile__third-level-navigation__button-list', false);
			// Calculate height of third lvl menu (without browser bars)
			if (menuItemsThirdLvl && menuItemsThirdLvl.offsetHeight > 0)
				calculateAndSetHeightOfMobileNavigationLevel(menuItemsThirdLvl);
		});
	};

	const onThirdMenuItemClick = (e: Event) => {
		e.stopPropagation();
	};

	const handleHeaderLink = (evTarget: EventTarget | null): boolean => {
		if (evTarget) {
			const target = evTarget as HTMLElement;
			if (target.nodeName.toLowerCase() === "a" || (target.parentNode && target.parentNode.nodeName.toLowerCase() === "a")) {
				return true;
			}
		}
		return false;
	};

	const onLanguageSelectorClick = (e: Event) => {
		const languageSelectorContainerEl = e.currentTarget as HTMLElement;
		const languageCurrentEl = expressQuerySelector(languageSelectorContainerEl, ".a-language-selector__current");
		const languageChoicesContainerEl = expressQuerySelector(languageSelectorContainerEl, ".a-language-selector__choices");
		const languageSelectorDescrEl = expressQuerySelector(languageSelectorContainerEl, ".a-language-selector__description");
		const languageSelectorDescrOptionsEl = expressQuerySelector(languageSelectorContainerEl, ".a-language-selector__description-options");

		if (languageCurrentEl)
			languageCurrentEl.classList.toggle("u-hide");
		if (languageChoicesContainerEl)
			languageChoicesContainerEl.classList.toggle("u-hide");
		if (languageSelectorDescrEl)
			languageSelectorDescrEl.classList.toggle("u-hide");
		if (languageSelectorDescrOptionsEl)
			languageSelectorDescrOptionsEl.classList.toggle("u-hide");
	};

	const attachCloseMenuClick = (closeContainerEl: HTMLElement) => {

		const closeButtonEl = expressQuerySelector(closeContainerEl, ".m-navigation-overlay__close-icon");
		if (closeButtonEl) {
			onCloseClickDisposable = compositeDisposable([expressEventListener(closeContainerEl, "click", onCloseMenuClick)]);
		}
	};

	const detachCloseMenuClick = () => onCloseClickDisposable && onCloseClickDisposable.dispose();

	const attachFirstMenuItemClick = (firstLevelMenuEls: HTMLElement[]) => {
		firstLevelMenuEls.forEach(e => expressEventListener(e, "click", onFirstMenuItemClick));
	};

	const attachSecondMenuItemClick = (secondLevelMenuEls: HTMLElement[]) => {
		secondLevelMenuEls.forEach(e => expressEventListener(e, "click", onSecondMenuItemClick));
	};

	const attachThirdMenuItemClick = (thirdLevelMenuEls: HTMLElement[]) => {
		thirdLevelMenuEls.forEach(e => expressEventListener(e, "click", onThirdMenuItemClick));
	};

	const attachLanguageSelectorClick = (languageSelectorEl: HTMLElement) => {
		onLanguageSelectorClickDisposable = compositeDisposable(
			[expressEventListener(languageSelectorEl, "click", onLanguageSelectorClick)]
		);
	};

	const detachLanguageSelectorClick = () => onLanguageSelectorClickDisposable && onLanguageSelectorClickDisposable.dispose();

	const menuButtonComponent = createClickableElement(menuButtonEl, onButtonClick);

	const deInit = () => {
		dispose();
		closeMenu();
	};

	// remove all handlers
	const dispose = () => {
		menuButtonComponent.dispose();
		detachLanguageSelectorClick();
		detachCloseMenuClick();
		detachDocumentClick();

		firstLevelMenuEls.forEach(e => e.removeEventListener("click", onFirstMenuItemClick));
		secondLevelMenuEls.forEach(e => e.removeEventListener("click", onSecondMenuItemClick));
		thirdLevelMenuEls.forEach(e => e.removeEventListener("click", onThirdMenuItemClick));
	};

	return {
		deInit
	};
}
