import {
	expressEventListener,
	expressQuerySelector,
	expressQuerySelectorAll,
	getHeightWithMargins,
	getParentWithClassName
} from "../common/html";
import { createUsps } from "./usp";
import { createCustomSlider, ICustomPhotoSliderItem, ICustomPhotoSliderWithDivSettings } from "./customslider";
import { IRecommendationEventParams, trackRecommendationCartEvent, trackSliderEvent } from "../common/events";
import { stopSkeletonLoading } from "./skeleton";
import { initVideoPlayer } from "./video-player";
import { createProductGrid } from "./product-grid";
import { createHighlightedTiles } from "./highlighted-tiles";
import { createImageDescription } from "./image-description";
import { createTruncate } from "./truncate";
import { createNewsletterSubscribe, createNewsletterUnsubscribe } from "./newsletter";
import { getParameter } from "../utils/querystring";

export function createHomepageElements() {
	window.showMores = [];
	const uspDesktopEls = expressQuerySelectorAll<HTMLElement>(document, '.technical-usp-desktop');

	// Initialize the usp's
	if (uspDesktopEls) {
		uspDesktopEls.forEach(uspDesktopEl => createUsps(uspDesktopEl));
	}

	window['initProductSliderComponent'] = function initProductSliderComponent(containerEl: HTMLElement) {
		const sliderEl = expressQuerySelector(containerEl, '.technical-slider', true);

		// convert so slider elements
		const sliderElements: ICustomPhotoSliderItem[] = [];
		for (let z = 0, l = sliderEl.children.length; z < l; z++)
			sliderElements.push({ template: sliderEl.children[z] as HTMLElement });

		// Gets the highest slide element in the slider elements
		const getHighestSlideOfSlider = (slidesInSlider: HTMLElement[]): HTMLElement => {
			let highestSlideEl: HTMLElement;
			let maxTitleHeight = 0;

			for (let i = slidesInSlider.length - 1; i >= 0; i--) {
				const slideEl = slidesInSlider[i] as HTMLElement;
				const sliderTitleEl = expressQuerySelector<HTMLElement>(slideEl, '.technical-slide-title', false);
				const sliderTitle = expressQuerySelector<HTMLElement>(slideEl, '.technical-tile-title', false);
				const sliderSubTitle = expressQuerySelector<HTMLElement>(slideEl, '.technical-tile-subtitle', false);

				const titleHeight = sliderTitle ? getHeightWithMargins(sliderTitle) : 0;
				const subTitleHeight = sliderSubTitle ? getHeightWithMargins(sliderSubTitle) : 0;
				const titleheight = titleHeight + subTitleHeight;

				// reset height
				if (sliderTitleEl)
					sliderTitleEl.style.height = '';

				if (maxTitleHeight <= titleheight) {
					maxTitleHeight = titleheight;
					highestSlideEl = slideEl;
				}
			}

			return highestSlideEl!;
		};

		// Set height of slides
		const heightScriptSlides = (slidesInSlider: HTMLElement[], highestSlideFullTitlepx: number) => {
			// SLIDES
			for (let i = 0, l = slidesInSlider.length; i < l; i++) {
				const slideFullTitleEl = expressQuerySelector<HTMLElement>(slidesInSlider[i] as HTMLElement, '.technical-slide-title', false);

				if (slideFullTitleEl && highestSlideFullTitlepx > 0)
					slideFullTitleEl.style.height = `${highestSlideFullTitlepx}px`;
			}
		};

		// Set height of buttons
		const heightScriptButtons = (slider: HTMLElement, titleHeight: number, highestSlideEl: HTMLElement) => {
			// BUTTONS
			const sliderButtons = expressQuerySelectorAll(slider, '.technical-slider-button');
			const hightestImage = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-slide-imageContainer', false);
			let buttonCenterImage = 0;
			if (hightestImage) {
				const hightestImagepx = hightestImage.offsetHeight;
				let bottom = highestSlideEl.offsetHeight - (hightestImagepx + titleHeight);
				if (bottom < 0) bottom = 0;
				buttonCenterImage = bottom + (hightestImagepx / 2);
			}

			for (let i = 0, l = sliderButtons.length; i < l; i++) {
				(sliderButtons[i] as HTMLElement).style.bottom = `${buttonCenterImage > 0 ? buttonCenterImage : (highestSlideEl.offsetHeight / 2)}px`;
			}
		};

		// settings for slider
		const settings = {
			sliderElements: sliderElements,
			sliderContainerSettings: {
				extraSliderClass: 'm-product-slider__slider-container',
				elementsContainerSettings: {
					extraSliderClass: 'm-product-slider__slider-content',
					slideClicked: (e: Event, settings: ICustomPhotoSliderWithDivSettings) => {
						const slider = getParentWithClassName<HTMLElement>(e.target as Element, 'technical-recommendations');
						if (!slider) return; // only have on click on recommendations

						const currentEl = getParentWithClassName<HTMLElement>(e.target as Element, 'technical-slide');
						const anchorEl = expressQuerySelector<HTMLAnchorElement>(currentEl, 'a');
						const label = {
							pageType: containerEl.getAttribute('data-pagetype') || slider.getAttribute('data-pagetype'),
							pageCode: containerEl.getAttribute('data-pageCode') || slider.getAttribute('data-pageCode'),
							pvc: getParameter('pvc', anchorEl.href, false) || ''
						} as IRecommendationEventParams;
						trackRecommendationCartEvent("Recommended product clicked", label);

						return;
					}
				},
				cssWhenButtonsBothOnMax: 'u-hide',
				nextButton: {
					css: 'a-button a-button--slider-button m-product-slider__button--next icon-arrow-right technical-slider-button',
					cssWhenOnMax: 'a-button--disabled',
					onClick: (_: Event, draggedSlider: boolean) => {
						trackSliderEvent(`nextbutton_${draggedSlider ? 'dragged' : 'clicked'}`);
						return;
					}
				},
				previousButton: {
					css: 'a-button a-button--slider-button m-product-slider__button--prev icon-arrow-left technical-slider-button',
					cssWhenOnMax: 'a-button--disabled',
					onClick: (_: Event, draggedSlider: boolean) => {
						trackSliderEvent(`prevbutton_${draggedSlider ? 'dragged' : 'clicked'}`);
						return;
					}
				},
			},
			showHalfSlides: false,
			replaceWithElement: true,
			enableEternalSliding: false,
			translateForMovement: true,

			// height script buttons
			SliderCreated: (settings, slider, oldSlider) => {
				// slider elements are on the DOM
				const visibleSlides = expressQuerySelectorAll(oldSlider ? oldSlider : sliderEl, '.technical-slide') as HTMLElement[];
				// new slider elements that has not been added to the DOM yet
				// Do this for the height script => minimize flikker, drag, slide, ... of elements when slider is on DOM
				const invisibleSlides = expressQuerySelectorAll(slider, '.technical-slide') as HTMLElement[];

				const highestSlideEl = getHighestSlideOfSlider(visibleSlides);
				const highestSlideTitle = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-tile-title', false);
				const highestSlideSubTitle = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-tile-subtitle', false);

				const highestSlideTitlepx = highestSlideTitle ? getHeightWithMargins(highestSlideTitle) : 0;
				const highestSlideSubTitlepx = highestSlideSubTitle ? getHeightWithMargins(highestSlideSubTitle) : 0;
				const highestSlideFullTitlepx = highestSlideTitlepx + highestSlideSubTitlepx;

				if (highestSlideFullTitlepx > 0)
					heightScriptSlides(invisibleSlides, highestSlideFullTitlepx);

				heightScriptButtons(slider, highestSlideFullTitlepx, highestSlideEl);
			},
			ResizeEvent: (settings, slider) => {
				const visibleSlides = expressQuerySelectorAll(slider, '.technical-slide') as HTMLElement[];
				const highestSlideEl = getHighestSlideOfSlider(visibleSlides);
				const highestSlideTitle = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-tile-title', false);
				const highestSlideSubTitle = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-tile-subtitle', false);

				const highestSlideTitlepx = highestSlideTitle ? getHeightWithMargins(highestSlideTitle) : 0;
				const highestSlideSubTitlepx = highestSlideSubTitle ? getHeightWithMargins(highestSlideSubTitle) : 0;
				const highestSlideFullTitlepx = highestSlideTitlepx + highestSlideSubTitlepx;

				if (highestSlideFullTitlepx > 0)
					heightScriptSlides(visibleSlides, highestSlideFullTitlepx);

				heightScriptButtons(slider, highestSlideFullTitlepx, highestSlideEl);
			}
		} as ICustomPhotoSliderWithDivSettings;

		// create slider
		if (sliderEl.parentElement) createCustomSlider(sliderEl.parentElement, settings);
	};

	// init highlighted tiles
	const highlightedTilesEl = expressQuerySelectorAll<HTMLElement>(document, ".technical-highlighted-tiles");
	highlightedTilesEl.forEach(x => createHighlightedTiles(x));

	const headerComponents = expressQuerySelectorAll<HTMLElement>(document, '.technical-header-component');
	headerComponents.forEach(header => {
		const truncateEl = expressQuerySelector<HTMLElement>(header, '.technical-shave', false);
		if (!truncateEl) return;
		createTruncate(truncateEl, { lines: 3 });
	});

	window['initProductGridComponent'] = function initProductGridComponent(containerEl: HTMLElement) {
		const grid = expressQuerySelector<HTMLElement>(containerEl, ".technical-product-grid", true);
		if (grid) createProductGrid(grid, {}, { refreshOnResize: true });
	};
	window['initMaterialTilesComponent'] = function initMaterialTilesComponent(containerEl: HTMLElement) {
		createImageDescription(containerEl);
		const videoTileEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-video-tile, .technical-video-container');
		videoTileEls.forEach(el => {
			if (!getParentWithClassName(el, 'technical-product-grid-content'))
				initVideoPlayer(el);
		});

		const newsletterSubscribeEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.m-material-tiles .technical-newsletter-subscribe');
		newsletterSubscribeEls.forEach(el => createNewsletterSubscribe(el));

		const newsletterUnSubscribeEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.m-material-tiles .technical-newsletter-unsubscribe');
		newsletterUnSubscribeEls.forEach(el => createNewsletterUnsubscribe(el));
	};

	const productGrids = expressQuerySelectorAll(document, ':not([data-component-callback=initProductGridComponent]) .technical-grid-and-filters');
	productGrids.forEach(x => window['initProductGridComponent'](x));

	const materialTiles = expressQuerySelectorAll(document, ':not([data-component-callback=initMaterialTilesComponent]) .technical-image-description');
	materialTiles.forEach(x => window['initMaterialTilesComponent'](x));

	const videoElements = expressQuerySelectorAll<HTMLElement>(document, '.technical-video');
	videoElements.forEach(x => initVideoPlayer(x));
	stopSkeletonLoading();
}
