﻿import {IDisposable} from "../utils/disposable";

export function expressQuerySelector<T extends keyof HTMLElementTagNameMap>(el: Element | Document, selectors: T, isRequired: true): HTMLElementTagNameMap[T];
export function expressQuerySelector<T extends keyof HTMLElementTagNameMap>(el: Element | Document, selectors: T, isRequired?: boolean): HTMLElementTagNameMap[T] | null;
export function expressQuerySelector<T extends Element>(el: Element | Document, selectors: string, isRequired: true): T;
export function expressQuerySelector<T extends Element>(el: Element | Document, selectors: string, isRequired?: boolean): T | null;
export function expressQuerySelector<T extends Element>(el: Element | Document, selectors: string, isRequired = false): T | null {
	const resultEl = el.querySelector<T>(selectors);
	if (isRequired && !resultEl) throw new Error("querySelector didn't return any results for: " + selectors);
	return resultEl;
}

/* Use the default js scrollIntoView => this code was created for IE if I remember correctly
export interface IScrollIntoViewOptions {
	readonly vertical?: 'center' | 'top' | 'bottom'; // TODO: implement bottom, top
	readonly behavior?: 'auto' | 'smooth';
}

export function scrollIntoView(el: HTMLElement, options: IScrollIntoViewOptions = {}) {
	const d = document;
	const w = window;
	const scrollAlignment = {"top": 0, "center": 0.5, "bottom": 1};
	const {vertical = 'center', behavior = 'smooth'} = options;
	const alignment = {y: scrollAlignment[vertical]};

	const hasScrollableSpace = (el: Element) => el.clientHeight < el.scrollHeight;

	const canOverflow = (el: Element) => {
		const overflowValue = w.getComputedStyle(el, null)['overflow' + 'Y'];
		return overflowValue === 'auto' || overflowValue === 'scroll';
	};

	const isScrollable = (el: Element) => hasScrollableSpace(el) && canOverflow(el);

	const findScrollableParent = (el: Element) => {
		while (el !== d.body && isScrollable(el) === false) {
			el = el.parentElement;
		}

		return el;
	};

	const scrollableParent = findScrollableParent(el);
	const clientRect = el.getBoundingClientRect();
	const parentRect = scrollableParent.getBoundingClientRect();
	const y = clientRect.top - parentRect.top - scrollableParent.scrollTop + ((parentRect.height - el.offsetHeight) * alignment.y);

	if (scrollableParent !== d.body) {
		'scrollBehavior' in document.documentElement.style
			? scrollableParent.scroll({
				top: y,
				behavior,
			})
			: scrollableParent.scrollTop = y; // IE: scroll on element not supported

	} else {
		const windowSize = getWindowSize();
		const y = Math.round(window.pageYOffset + clientRect.top - (windowSize.height - el.offsetHeight) * alignment.y);
		'scrollBehavior' in document.documentElement.style
			? window.scroll({
				top: y,
				behavior,
			})
			: window.scroll(0, y); // IE: scroll options not supported
	}
}*/

export function getWindowSize() {
	const w = window;
	const d = document;
	const e = d.documentElement;
	const g = d.body;
	const x = w.innerWidth || e.clientWidth || g.clientWidth;
	const y = w.innerHeight || e.clientHeight || g.clientHeight;

	return {width: x, height: y};
}

export function hasClass(element: HTMLElement, cssClass: string): boolean {
	const regex = new RegExp('(?:^|\\s)' + cssClass + '(?!\\S)');
	const match = element.className.match(regex);
	return !!match && !!match.length;
}

export function expressEventListener<T>(element: any, type: string, listener: (ev: any) => T, useCapture?: boolean): IDisposable {
	const disposable = {
		dispose: (): void => {
			if (disposable.handler) { // Prevent unregistering twice
				element.removeEventListener(disposable.type, disposable.handler, useCapture);
				delete disposable.handler;
			}
		},
		handler: (ev: any): T => {
			return listener(ev);
		},
		type
	};

	element.addEventListener(disposable.type, disposable.handler, useCapture);

	return disposable;
}

export function expressQuerySelectorAll<T extends Element>(el: Element | Document | DocumentFragment, selectors: string): T[] {
	return el ? [].slice.call(el.querySelectorAll<T>(selectors)) : [];
}

export function expressAddClass(element: HTMLElement, cssClass) {
	expressToggleClass(element, cssClass, true);
}

export function expressRemoveClass(element: HTMLElement, cssClass) {
	expressToggleClass(element, cssClass, false);
}

export function expressToggleClass(element: HTMLElement, cssClass: string, on: boolean) {
	const regex = new RegExp('(?:^|\\s)' + cssClass + '(?!\\S)');

	const hasClass = element.className.match(regex);
	if (hasClass && !on) {
		element.className = element.className.replace(new RegExp(regex.source, "g"), '');
	} else if (!hasClass && on) {
		element.className += " " + cssClass;
	}
}

export function show(el: HTMLElement) {
	if (el.offsetParent === null) { // is el visible in dom?
		if (el.style.display === 'none') { // check style attribute
			el.style.display = '';
		} else {
			if (getComputedStyle(el).display === 'none') { // el not visible -> check css display -> force display block
				el.style.display = 'block';
			}
		}
	}
}

export function hide(el: HTMLElement) {
	el.style.display = 'none';
}

export function getHeightWithMargins(el: HTMLElement): number {
	return el.getBoundingClientRect().height
		+ parseInt(window.getComputedStyle(el).marginTop || "0", 10)
		+ parseInt(window.getComputedStyle(el).marginBottom || "0", 10);
}

export function getParentWithClassName<T extends Element>(el: Element | null, className: string): T | null {
	if (el)
		el = el.parentElement;

	while (el) {
		if (el.classList.contains(className))
			return <T>el;
		el = el.parentElement;
	}

	return null;
}

export function getNextSiblingWithClassName<T extends Element>(el: Element | null, className: string): T | null {
	if (el)
		el = el.nextElementSibling;

	while (el) {
		if (el.classList.contains(className))
			return <T>el;
		el = el.nextElementSibling;
	}

	return null;
}

export function getParentWithId<T extends Element>(el: Element | null, id: string): T | null {
	if (el)
		el = el.parentElement;

	while (el) {
		if (el.id === id)
			return <T>el;
		el = el.parentElement;
	}

	return null;
}

export function getParents (elem, selector?) {
	if (!Element.prototype.matches) {
		Element.prototype.matches =
			Element.prototype.webkitMatchesSelector ||
			function(s) {
				const matches = (this.document || this.ownerDocument).querySelectorAll(s);
				let i = matches.length;
				while (--i >= 0 && matches.item(i) !== this) {
					//
				}
				return i > -1;
			};
	}
	// We check on the parents of an element => select the first parent
	elem = elem.parentNode;
	const parents = [];
	for ( ; elem && elem !== document; elem = elem.parentNode ) {
		if (selector) {
			if (elem.matches(selector)) {
				parents.push(elem);
			}
			continue;
		}
		parents.push(elem);
	}
	return parents;

}

export function htmlToElement(html): ChildNode {
	const template = document.createElement('template');
	html = html.trim(); // Never return a text node of whitespace as the result
	template.innerHTML = html;
	return template.content.firstChild;
}

export function isMobileOnPageWithHeader() {
	return isElementVisible(
		expressQuerySelector<HTMLElement>(
			document,
			'.o-main-navigation__hamburger-button',
			true
		)
	);
}

export function isElementVisible(el: HTMLElement) {
	return !(el.offsetWidth
		+ el.offsetHeight
		+ el.getBoundingClientRect().height
		+ el.getBoundingClientRect().width === 0);
}

export function isElementVisibleInViewPort(el: HTMLElement): boolean {
	if (!el) return false; // return default
	const rect = el.getBoundingClientRect();

	return rect.top >= 0
		&& rect.left >= 0
		&& rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
		&& rect.right <= (window.innerWidth || document.documentElement.clientWidth);
}

export function isElementPartiallyVisibleInViewport(el, percentVisible) {
	if (!el) return false;
	const rect = el.getBoundingClientRect();
	const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

	return !(
		Math.floor(100 - (((rect.top >= 0 ? 0 : rect.top) / +-rect.height) * 100)) < percentVisible ||
		Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
	);
}

export function remove(el: Element) {
	if (el.parentElement) el.parentElement.removeChild(el);
}

let inViewArray: { element: HTMLElement; callBack: IInViewCallback; }[];

interface IInViewCallback {
	(element: HTMLElement): void;
}

// Can be replaced with a intersection observer with threshold = 1
export function inViewTrigger(element: HTMLElement, callBack: IInViewCallback) {
	const inViewScrollHandler = () => {
		// What does in view mean? We assume people are scrolling down, so if BOTTOM of the element is in view -> the element has been in view!
		for (let i = inViewArray.length - 1; i >= 0; i--) {
			const { element, callBack } = inViewArray[i];

			const windowHeight = window.innerHeight;
			const scrollY = window.scrollY || window.pageYOffset;
			const scrollPosition = scrollY + windowHeight;

			const elementHeight = element.clientHeight;
			const elementPosition = element.getBoundingClientRect().top + scrollY + elementHeight;

			if (scrollPosition > elementPosition) {
				callBack(element);
				inViewArray.splice(i, 1);
			}
		}

		if (inViewArray.length === 0) {
			// I no longer need myself! Cleanup....
			document.removeEventListener('scroll', inViewScrollHandler);
		}
	};

	// We should only have 1 scroll event listener that checks all elements that want to use this feature.
	if (!inViewArray || inViewArray.length === 0) {
		inViewArray = [];
		document.addEventListener('scroll', inViewScrollHandler);
	}

	inViewArray.push({ element, callBack });

	// Do 1 check without scrolling -> items might already be completely in view
	inViewScrollHandler();
}

export function disableUserEvents(): IDisposable {
	const eventsToBlock = ["click", "keydown", "keyup", "mouseenter", "mouseleave", "focus"];

	const disposable = {
		blockingEvents: eventsToBlock.map((event) => expressEventListener(window, event, (e) => blockEvent(e), true)),
		dispose: (): void => {
			if (disposable.blockingEvents) {
				disposable.blockingEvents.forEach((blockedEvent) => blockedEvent.dispose());
			}
		}
	};

	return disposable;
}

const blockEvent = (event: Event): void => {
	event.preventDefault();
	event.stopPropagation();
};
