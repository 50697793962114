﻿import { expressEventListener, expressQuerySelectorAll } from "../common/html";
import { getParameter, updateParameter } from "../utils/querystring";
import { compositeDisposable } from "../utils/disposable";

export interface IShowMoreElementsComponent {
	dispose: () => void;
}

export interface IShowMoreDeps {
	readonly buttonClicked: (batchSize: number) => Promise<boolean>;
	readonly parameter?: string;
}

export function initPagedShowMoreElements(buttonEl: HTMLElement, deps: IShowMoreDeps) {
	const { parameter, buttonClicked } = deps;
	const batchSize = parseInt(buttonEl.dataset['batchsize'] || '999', 10);
	let currentPage = parseInt(getParameter(parameter || "currentpage") || buttonEl.dataset.currentpage || '0', 0);

	const showMore = () => {
		currentPage = currentPage + 1;
		buttonEl.dataset.currentpage = currentPage.toString();
		if (parameter) {
			let url = location.href;
			const urlParameter = parameter ? parameter : "currentpage";
			url = updateParameter(urlParameter.toLowerCase(), buttonEl.dataset.currentpage, url.toLowerCase());
			history.replaceState(null, '', url.toLowerCase());
		}

		buttonClicked && buttonClicked(batchSize).then(res => buttonEl.classList.toggle('u-hide', res));
	};

	const disposableEvents = compositeDisposable(
		[
			expressEventListener(buttonEl, 'click', (e: Event) => {
				e.preventDefault();
				showMore();
			})
		]
	);

	return {
		dispose: () => disposableEvents.dispose()
	};
}

export interface ShowMoreClickedReturn {
	allShownDesktop: boolean;
	allShownMobile: boolean;
}

export function initShowMoreElements(containerEl: HTMLElement, buttonEl: HTMLElement, parameter?: string, elementselector?: string, arrayCounter = -1, showmoreClick?: (currentPage: number, sizeDesktop: number, sizeMobile: number) => Promise<ShowMoreClickedReturn>, afterShowMore?: () => void): IShowMoreElementsComponent {
	const elementElsDesktop = expressQuerySelectorAll(containerEl, (elementselector ? elementselector : '.technical-showmore') + '.u-hide-on-desktop-full');
	const elementElsMobile = expressQuerySelectorAll(containerEl, (elementselector ? elementselector : '.technical-showmore') + '.u-hide-on-mobile-full');
	const batchSizeDesktop = parseInt(buttonEl.dataset['batchsizeDesktop'] || '999', 10);
	const batchSizeMobile = parseInt(buttonEl.dataset['batchsizeMobile'] || '999', 10);
	const showAllOnMore = buttonEl.dataset['showallonmore'] || false;
	const urlParam = parseInt(getParameter(parameter || "currentpage"));
	let currentPage = isNaN(urlParam) ? parseInt(buttonEl.dataset.currentpage || '0', 0) : urlParam;

	if (elementElsDesktop.length === 0 && elementElsMobile.length === 0 && !showmoreClick)
		return;

	const toggleButtonVisibility = (allDesktopVisiable: boolean, allMobileVisiable: boolean) => {
		if (allDesktopVisiable || showAllOnMore) {
			if (buttonEl.parentElement && !buttonEl.classList.contains('technical-no-hide-parrent'))
				buttonEl.parentElement.classList.add('u-hide-on-desktop-full');
			else
				buttonEl.classList.add('u-hide-on-desktop-full');
		}

		if (allMobileVisiable || showAllOnMore) {
			if (buttonEl.parentElement && !buttonEl.classList.contains('technical-no-hide-parrent'))
				buttonEl.parentElement.classList.add('u-hide-on-mobile-full');
			else
				buttonEl.classList.add('u-hide-on-mobile-full');
		}
	};

	const showElements = (sizeMobile: number, sizeDesktop: number) => {
		if (showmoreClick)
			return showmoreClick(currentPage, sizeDesktop, sizeMobile)
				.then(x => {
					toggleButtonVisibility(x.allShownDesktop, x.allShownMobile);
					afterShowMore && afterShowMore();
				});

		const toShowDesktop = showAllOnMore ? elementElsDesktop : elementElsDesktop.splice(0, sizeDesktop);
		toShowDesktop.forEach(e => e.classList.remove('u-hide-on-desktop-full'));

		const toShowMobile = showAllOnMore ? elementElsMobile.splice(0, elementElsMobile.length) : elementElsMobile.splice(0, sizeMobile);
		toShowMobile.forEach(e => e.classList.remove('u-hide-on-mobile-full'));

		toggleButtonVisibility(elementElsDesktop.length === 0, elementElsMobile.length === 0);
		afterShowMore && afterShowMore();
	};

	const showMore = () => {
		currentPage = currentPage + 1;
		buttonEl.dataset.currentpage = currentPage.toString();
		if (parameter) {
			let url = location.href;
			let urlParameter = parameter ? parameter : "currentpage";
			if (arrayCounter >= 0) urlParameter += "[" + arrayCounter + "]";
			url = updateParameter(urlParameter.toLowerCase(), buttonEl.dataset.currentpage, url.toLowerCase());
			history.replaceState(null, '', url.toLowerCase());
		}

		showElements(batchSizeMobile, batchSizeDesktop);
	};

	const disposableEvents = compositeDisposable(
		[
			expressEventListener(buttonEl, 'click', (e: Event) => {
				e.preventDefault();
				showMore();
			})
		]
	);

	// init
	// currentPage on button different from url? => html from cache server side => init showmore here
	const toCheckParameter = (arrayCounter > -1 && parameter ? `${parameter}[${arrayCounter}]` : parameter);
	if (currentPage > 0 && parseInt(getParameter(toCheckParameter || "currentpage")) != parseInt(buttonEl.dataset.currentpage || '0'))
		for (let i = 0; i < currentPage; i++) {
			showElements(batchSizeMobile, batchSizeDesktop);
		}

	return {
		dispose: () => disposableEvents.dispose()
	};
}
