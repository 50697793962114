import { expressQuerySelector, hasClass, expressRemoveClass } from "../common/html";
export declare class Truncate {
	constructor(element: HTMLElement, options: ITruncateOptions)
	collapse(): void;
	expand(): void;
	update(html: string): void;
	config(config: any): void;
}

export interface ITruncate {
	dispose: () => void;
	updateConfig: (opts: ITruncateOptions, overrideDefault?: boolean) => void;
	updateContent: (html: string) => void;
	collapse: () => void;
}

export interface ITruncateOptions {
	lineHeight?: number;
	lines?: number;
	ellipsis?: string;
	showMore?: string;
	showLess?: string;
	position?: string;
	maxHeight?: number;
	eventTrackingMethod?: (action: string) => void;
	closeEventAction?: string;
	openEventAction?: string;
}

export function createTruncate(containerEl: HTMLElement, opts: ITruncateOptions) {
	// calculate the height yourself
	// => package does same calculations but with parseInt() so there is no support for float lineheights then visable lines -= 1
	// height of a element auto rounds to a round number do the same here
	const lineHeight = Math.ceil(opts.lineHeight ? opts.lineHeight : parseFloat(window.getComputedStyle(containerEl).lineHeight || "0"));
	const lines = Math.ceil(opts.lines ? opts.lines : 1);
	const maxHeight = Math.ceil(opts.maxHeight ? opts.maxHeight : lineHeight * lines);
	const shaveMoreOnContainer = expressQuerySelector(containerEl, '.technical-shave-more');
	const shaveLessOnContainer = expressQuerySelector(containerEl, '.technical-shave-less');

	const defaults: ITruncateOptions = {
		ellipsis: '\u2026 ',
		showMore: opts.showMore || (shaveMoreOnContainer ? shaveMoreOnContainer.outerHTML : expressQuerySelector(document, '.technical-shave-more').outerHTML),
		showLess: opts.showLess || (shaveLessOnContainer? shaveLessOnContainer.outerHTML : expressQuerySelector(document, '.technical-shave-less').outerHTML),
		lineHeight: lineHeight,
		lines: lines,
		maxHeight: maxHeight < lineHeight ? lineHeight : maxHeight // a minimum of 1 line is visible (if max height > line height => no content is shown on the page)
	};

	if (hasClass(containerEl, "truncate-loading-1-line")) expressRemoveClass(containerEl, "truncate-loading-1-line");
	if (hasClass(containerEl, "truncate-loading-5-lines--pcp")) expressRemoveClass(containerEl, "truncate-loading-5-lines--pcp");

	const onClick = (ev: MouseEvent) => {
		const currentEl = ev.target as HTMLElement;
		if (currentEl.classList.contains("technical-shave-more")) {
			if (opts.eventTrackingMethod && opts.openEventAction) opts.eventTrackingMethod(opts.openEventAction);
			truncate.expand();
		}
		else if (currentEl.classList.contains("technical-shave-less")) {
			if (opts.eventTrackingMethod && opts.closeEventAction) opts.eventTrackingMethod(opts.closeEventAction);
			truncate.collapse();
		}
	};

	const updateConfig = (newOpts: ITruncateOptions, overrideDefault?: boolean) => {
		truncate.config(overrideDefault
			? {
				ellipsis: defaults.ellipsis,
				showMore: defaults.showMore,
				showLess: defaults.showLess,
				lineHeight: defaults.lineHeight,
				lines: newOpts.lines,
				maxHeight: newOpts.maxHeight
			}
			: { ...newOpts, ...defaults });
		truncate.collapse();
	};

	const updateContent = (html: string) => {
		truncate.update(html);
		truncate.collapse();
	};

	const collapse = () => {
		truncate.collapse();
	};

	// We need to find a nog jquery alternative to our truncate.js
	const truncate = new Truncate(containerEl, { ...opts, ...defaults });
	containerEl.addEventListener('click', onClick);

	return {
		dispose: () => containerEl.removeEventListener('click', onClick),
		updateConfig,
		updateContent,
		collapse
	} as ITruncate;
}
