﻿import {expressQuerySelector, expressQuerySelectorAll, hasClass, isElementVisibleInViewPort} from '../common/html';
import { createClickableElement } from './button';

export interface ICreateCollapsableOptions {
	readonly singleOpen: boolean;
}

export interface ICreateCollapsableDependencies {
	onInit?: (container: HTMLElement) => Promise<void>;
	onBeforeOpen?: (container: HTMLElement) => Promise<void>;
	readonly onBeforeClose?: (container: HTMLElement) => Promise<void>;
	readonly titleElSelector: string;
	readonly scrollIntoViewAfterOpen: boolean;
	readonly contentSelector?: string;
	readonly openClass?: string;
}

export interface ICollapsibleContentRequest {
	readonly type: string;
}

export interface ICollapsable {
	dispose(): void;
	show(): void;
	close(): void;
	hide(): void;
	open(scrollIntoView?: boolean): void;
	element: HTMLElement;
}

export function createCollapsable(container: HTMLElement, deps: ICreateCollapsableDependencies, opts?: ICreateCollapsableOptions): ICollapsable {
	const { onInit, onBeforeOpen, onBeforeClose, titleElSelector, scrollIntoViewAfterOpen, openClass, contentSelector } = deps;
	const { singleOpen } = opts || {};
	const OPEN_CLASS = openClass ? openClass : "is-collapse-open";
	const titleEl = expressQuerySelector<HTMLElement>(container, titleElSelector, true);
	const contentEl = expressQuerySelector<HTMLElement>(container, contentSelector ? contentSelector : '.technical-collapse-content', false);

	const closeWithBefore = () => {
		if (!onBeforeClose) {
			close();
			return Promise.resolve();
		}

		return onBeforeClose(container).then(() => close());
	};

	const openWithBefore = (scrollIntoView = false) => {
		if (!onBeforeOpen) {
			open();
			return Promise.resolve();
		}

		return onBeforeOpen(container).then(() => open(scrollIntoView));
	};

	const close = () => container.classList.remove(OPEN_CLASS);
	const open = (shouldScrollIntoView?: boolean) => {
		if (singleOpen) {
			const collapsibleEls = expressQuerySelectorAll<HTMLElement>(container.parentElement, '.m-collapsable');
			collapsibleEls.forEach(el => el.classList.remove(OPEN_CLASS));
		}
		container.classList.add(OPEN_CLASS);
		if ((scrollIntoViewAfterOpen && !isElementVisibleInViewPort(contentEl)) || shouldScrollIntoView)
			titleEl.scrollIntoView({ behavior: "smooth", block: "start" });
	};

	const isOpen = () => hasClass(container, OPEN_CLASS);

	const onClick = () => !isOpen() ? openWithBefore() : closeWithBefore();

	const button = createClickableElement(titleEl, onClick);

	onInit && onInit(container);

	return {
		dispose: button.dispose,
		show: () => container.classList.remove('u-hide'),
		hide: () => container.classList.add('u-hide'),
		close,
		open: (scrollIntoView?: boolean) => openWithBefore(scrollIntoView),
		element: container
	} as ICollapsable;
}
