// returns format YYYY-MM-DD (for html5 date input fields)
export function getFormattedDate(date: Date): string {
	let year = date.getFullYear().toString();
	year = year.length > 3 ? year
		: year.length > 2 ? '0' + year
			: year.length > 1 ? '00' + year
				: year.length > 0 ? '000' + year
					: '0000';
	let month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;
	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;
	return year + '-' + month + '-' + day;
}

export function getDate(dateString: string): Date {
	const regex = new RegExp(/(^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$)|(^\d{4}[-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$)/);
	if (!regex.test(dateString))
		throw Error("invalid date format: " + dateString + ". Expected either YYYY-MM-DD or DD-MM-YYYY");

	let date: Date;
	const firstPart = dateString.substr(0, dateString.indexOf("-"));
	if (firstPart.length === 4)
		date = new Date(dateString);
	else {
		const dateParts = dateString.split("-").map(s => +s);
		date = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
	}

	return date;
}

export function convertDateToUtcDate(date: Date): Date {
	return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}
