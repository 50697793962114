﻿// SG organism: http://brandplatform.smartphoto.com/general/organisms/highlighted-tiles/
import { expressEventListener, expressQuerySelector, expressQuerySelectorAll } from "../common/html";
import { IDisposable } from "../utils/disposable";
import { debounce } from "../utils/debounce";
import { createTruncate, ITruncate, ITruncateOptions } from "./truncate";
import {initVideoPlayer} from "./video-player";

export enum Device {
	Desktop = 1,
	Tablet = 2,
	Mobile = 3
}

export function createHighlightedTiles(containerEl: HTMLElement) {
	const desktopTileEls = expressQuerySelectorAll<HTMLElement>(containerEl, ".o-highlighted-tiles__tile:not(.hide-on-desktop):not(.u-hide-on-desktop)");
	const tabletTileEls = expressQuerySelectorAll<HTMLElement>(containerEl, ".o-highlighted-tiles__tile:not(.hide-on-tablet):not(.u-hide-on-tablet)");
	const truncateEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-shave');
	const videoEls = expressQuerySelectorAll<HTMLElement>(containerEl, '.technical-video-tile');

	const tileEl = expressQuerySelectorAll<HTMLElement>(containerEl, '.o-highlighted-tiles__tile')[0];
	const headerAndTilesEl = expressQuerySelector<HTMLElement>(containerEl, '.o-highlighted-tiles__header_and_tiles', true);
	const titleEl = expressQuerySelector<HTMLElement>(headerAndTilesEl, '.m-header__title', false);
	const subTitleEl = expressQuerySelector<HTMLElement>(headerAndTilesEl, '.m-header__subtitle', false);
	const textEl = expressQuerySelector<HTMLElement>(headerAndTilesEl, '.m-header__text', false);

	let eventDisposable: IDisposable;
	let currentDevice: Device;

	const getDevice = (): Device => {
		if (window.innerWidth <= 767) {
			return Device.Mobile;
		} else if (window.innerWidth > 768 && window.innerWidth < 960) {
			return Device.Tablet;
		} else {
			return Device.Desktop;
		}
	};

	const outerHeight = (el: HTMLElement, includeOffset = true) => {
		let height = includeOffset ? el.offsetHeight : 0;
		const style = getComputedStyle(el);
		height += parseInt(style.marginTop || '0', 10) + parseInt(style.marginBottom || '0', 10);
		return height;
	};

	const calculateTruncateMaxHeight = (): number => {
		let maxHeight = 0;
		maxHeight = outerHeight(tileEl);
		titleEl && (maxHeight -= outerHeight(titleEl));
		subTitleEl && (maxHeight -= outerHeight(subTitleEl));
		textEl && (maxHeight -= outerHeight(textEl, false));
		return maxHeight;
	};

	const getTruncateOptions = (): ITruncateOptions => {
		currentDevice = getDevice();
		const options: ITruncateOptions = {};
		switch (currentDevice) {
			case Device.Desktop:
				switch (desktopTileEls.length) {
					case 0:
					case 3:
						options.lines = 3;
						options.maxHeight = undefined;
						break;
					default:
						options.maxHeight = calculateTruncateMaxHeight();
						options.lines = undefined;
						break;
				}
				break;
			case Device.Tablet:
				switch (tabletTileEls.length) {
					case 1:
						options.maxHeight = calculateTruncateMaxHeight();
						options.lines = undefined;
						break;
					default:
						options.lines = 2;
						options.maxHeight = undefined;
						break;
				}
				break;
			default: // mobile
				options.lines = 2;
				options.maxHeight = undefined;
				break;
		}

		return options;
	};

	const onResize = () =>
		currentDevice !== getDevice() &&
		truncatedEls.forEach(t => t.updateConfig(getTruncateOptions()));

	let truncatedEls: ITruncate[] = [];

	const init = () => {
		truncatedEls = truncateEls.map(el => createTruncate(el, getTruncateOptions()));
		eventDisposable = expressEventListener(window, "resize", debounce(onResize, 250));
		videoEls && videoEls.forEach(vEl => initVideoPlayer(vEl));
	};

	init();

	return {
		dispose: () => {
			eventDisposable.dispose();
			truncatedEls.forEach(t => t.dispose());
		}
	};
}
