﻿import {initTooltip} from "./tooltip";
import {expressEventListener, expressQuerySelector} from "../common/html";
import {getHtmlRequest} from "../utils/fetch";
import { IDisposable } from "../utils/disposable";

export interface IInitShoppingCartDependencies {
	readonly toggleScroll?: () => void;
	readonly onOpen?: () => void;
	readonly onClose?: () => void;
}

export function initShoppingCart(shoppingCartMenuEl: HTMLElement, itemCountIcon: HTMLElement | null, deps: IInitShoppingCartDependencies) {
	const { toggleScroll, onOpen, onClose } = deps;

	const toggleScrollClass = () => {
		if (toggleScroll) toggleScroll();
	};

	let loaded = false;

	const onCartTooltipOpen = (containerEl: HTMLElement) => {
		const cartContainerEl = expressQuerySelector(containerEl, ".technical-shopping-cart-container", true);
		const cartHasItems = cartContainerEl.getAttribute('data-load') || undefined;
		if (!loaded && (cartHasItems === 'true')) {
			getHtmlRequest("/ajaxxhr/Header/LoadBasket").then(res => {
				cartContainerEl.innerHTML = res;
				if (itemCountIcon && expressQuerySelector(cartContainerEl, '.m-tooltip__empty-cart', false))
					itemCountIcon.remove();
				loaded = true;
			});
		}

		toggleScrollClass();
	};

	return initTooltip(
		{
			buttonEl: shoppingCartMenuEl.previousElementSibling as HTMLElement,
			containerEl: shoppingCartMenuEl,
			onOpen: (containerEl: HTMLElement) => { onOpen && onOpen(); onCartTooltipOpen(containerEl); },
			onClose: () => { onClose && onClose(); toggleScrollClass(); },
			iconCloseSelector: ".technical-icon-close",
			openClass: "menu-is-open",
			tooltipSelector: ".o-icon-navigation__shopping-cart-icon",
			toggleIconSelector: ".icon-shopping-basket",
			useCapture: true
		}
	);
}
