﻿import { createClickableElement } from "./button";
import { compositeDisposable, IDisposable } from "../utils/disposable";
import { expressEventListener, expressQuerySelector, expressQuerySelectorAll } from "../common/html";
import { IIconResult } from "../utils/icons";
import { calculateAndSetHeightOfMobileNavigationLevel } from "../utils/mobile-menu";

export interface IInitMyAccountDependencies {
	readonly getIconsAsync: (ids: string[]) => Promise<IIconResult[]>;
	readonly onOpen?: () => void;
	readonly onClose?: () => void;
	readonly isMobile: () => boolean;
	readonly loadAccountMenu: () => Promise<string>;
}

export function initMyAccount(mainNavigationEl: HTMLElement, deps: IInitMyAccountDependencies) {

	const { getIconsAsync, onClose, onOpen, isMobile, loadAccountMenu } = deps;
	let onDocumentClickDisposable: IDisposable;
	const icons = {};
	let loaded = false;
	const buttonEl = expressQuerySelector<HTMLElement>(mainNavigationEl, '.o-icon-navigation__user-icon', true);
	const menuEl = expressQuerySelector<HTMLElement>(mainNavigationEl, '.o-icon-navigation__user-menu', true);
	const overLayEl = expressQuerySelector<HTMLElement>(menuEl, '.technical-navigation-overlay', true);
	const contentEl = expressQuerySelector<HTMLElement>(overLayEl, '.m-navigation-overlay__content', true);
	const closeButtonEl = expressQuerySelector<HTMLElement>(overLayEl, '.m-navigation-overlay__close-container', true);

	const setOverLayClass = () => {
		overLayEl.classList.remove(...["m-navigation-overlay__mobile-view", "m-navigation-overlay__desktop-view"]);
		overLayEl.classList.add(isMobile() ? "m-navigation-overlay__mobile-view" : "m-navigation-overlay__desktop-view");
	};

	const onOpenMyAccount = () => {
		if (!loaded) {
			return loadAccountMenu()
				.then(res => {
					contentEl.insertAdjacentHTML('beforeend', res);
					const buttonEls = expressQuerySelectorAll<HTMLElement>(contentEl, '.technical-hassvg')
						.filter(el => !!el.getAttribute('data-icon-svg'));
					const urls = buttonEls
						.map(el => el.getAttribute('data-icon-svg')!)
						.filter((value, index, self) => self.indexOf(value) === index);

					// this can fire & forget
					getIconsAsync(urls)
						.then(
							res => {
								res.forEach(icon => icons[icon.url] = icon.svg);
								buttonEls.forEach(el => el.insertAdjacentHTML('beforeend', icons[el.getAttribute('data-icon-svg')!]));
							}
						);

					loaded = true;
				});
		} else {
			return Promise.resolve();
		}
	};

	const closeMenu = () => {
		onClose && onClose();
		menuEl.classList.remove("menu-is-open");
		detachDocumentClick();
		return Promise.resolve();
	};

	const openMenu = () => {
		setOverLayClass();
		onOpen && onOpen();
		return onOpenMyAccount()
			.then(() => {
				menuEl.classList.add("menu-is-open");

				if (isMobile()) {
					const menuButtonList = expressQuerySelector<HTMLElement>(menuEl, '.o-my-account__list', true);
					calculateAndSetHeightOfMobileNavigationLevel(menuButtonList);
				}

				attachOnDocumentClick();
			});
	};

	const onCloseIconClick = (e: Event) => {
		e.stopPropagation();
		closeMenu();
	};

	const onButtonClick = () => {
		return menuEl.classList.contains("menu-is-open")
			? closeMenu()
			: openMenu();
	};

	const onDocumentClick = (e: MouseEvent) => {
		if (!(e.target as HTMLElement).closest(".o-icon-navigation__user-menu, .o-icon-navigation__user-icon")) {
			closeMenu();
		}
	};

	const button = createClickableElement(buttonEl, onButtonClick);
	const eventDisposables = compositeDisposable(
		[

			expressEventListener(closeButtonEl, 'click', onCloseIconClick),
			expressEventListener(window, 'resize', setOverLayClass)
		]
	);
	const attachOnDocumentClick = () =>
		(onDocumentClickDisposable = expressEventListener(document, 'click', onDocumentClick, true));

	const detachDocumentClick = () => onDocumentClickDisposable && onDocumentClickDisposable.dispose();

	return {
		dispose: () => {
			eventDisposables.dispose();
			detachDocumentClick();
			button.dispose();
			closeMenu();
		}
	};

}
