﻿import {
	expressQuerySelector,
	expressQuerySelectorAll,
	expressAddClass,
	expressRemoveClass,
	hide,
	show,
	isElementVisible
} from "../common/html";
import {initForm} from "./form";
import {subscribeAsync, unSubscribeAsync, getPersonIdAsync} from "../services/account-service";
import {getParameter} from "../utils/querystring";
import { getCookie } from "../utils/cookie";

export function createNewsletterSubscribeAndUnsubscribe(customDocument: Element = null) {
	const subscriptionForms = expressQuerySelectorAll<HTMLElement>(customDocument ? customDocument : document, ".technical-newsletter-subscribe");
	const unSubscriptionForms = expressQuerySelectorAll<HTMLElement>(customDocument ? customDocument : document, ".technical-newsletter-unsubscribe");

	if (subscriptionForms && subscriptionForms.length > 0) {
		let ioSubscribe: IntersectionObserver;
		if (('InterSectionObserver' in window || 'IntersectionObserverEntry' in window && 'intersectionRatio' in (window as any).IntersectionObserverEntry.prototype)) {
			ioSubscribe = new IntersectionObserver((entries, observer) => {
				entries.forEach(entry => {
						if (entry.isIntersecting) {
							createNewsletterSubscribe(entry.target as HTMLElement);
							observer.unobserve(entry.target);
						}
					});
				},
				{rootMargin: "0px 0px 0px 0px"}
			);
			subscriptionForms.forEach(el => ioSubscribe.observe(el));
		} else {
			subscriptionForms.forEach(formEL => {
				createNewsletterSubscribe(formEL);
			});
		}
	}

	if (unSubscriptionForms && unSubscriptionForms.length > 0) {
		let ioUnSubscribe: IntersectionObserver;
		if (('InterSectionObserver' in window || 'IntersectionObserverEntry' in window && 'intersectionRatio' in (window as any).IntersectionObserverEntry.prototype)) {
			ioUnSubscribe = new IntersectionObserver((entries, observer) => {
					entries.forEach(entry => {
						if (entry.isIntersecting) {
							createNewsletterUnsubscribe(entry.target as HTMLElement);
							observer.unobserve(entry.target);
						}
					});
				},
				{rootMargin: "0px 0px 0px 0px"}
			);
			unSubscriptionForms.forEach(el => ioUnSubscribe.observe(el));
		} else {
			unSubscriptionForms.forEach(formEL => {
				createNewsletterUnsubscribe(formEL);
			});
		}
	}
}

export function createNewsletterSubscribe(containerEl: HTMLElement) {
	const formEl = expressQuerySelector<HTMLFormElement>(containerEl, 'form', true);
	if (!isElementVisible(formEl)) return;
	const emailEl = expressQuerySelector<HTMLInputElement>(formEl, '.technical-subscribe-email', true);
	const emptyGuid = '00000000-0000-0000-0000-000000000000';
	const appId = containerEl.dataset.appid || emptyGuid;
	const posId = containerEl.dataset.posid || emptyGuid;
	const lang = containerEl.dataset.lang || "";
	const email = getCookie('LastUsedEmail');

	emailEl.setAttribute('required', 'required');
	emailEl.setAttribute('emailRFC822', 'true');
	// we prefill the email client side because of the html caching
	if (email) emailEl.value = email;

	let source = formEl.dataset.source || undefined;
	!source && (source = emailEl.dataset.sourceid);

	const subscribeForm = initForm(
		formEl,
		{
			onSubmitForm: () => {
				return subscribeAsync(emailEl.value, appId, posId, lang, source, true, false)
					.then(
						() => {
							expressAddClass(containerEl, "m-newsletter--success");
							expressAddClass(formEl, 'm-newsletter__form');
						}, (e) => {
							expressRemoveClass(containerEl, "m-newsletter--success");
							expressAddClass(formEl, 'm-newsletter__form');
						}
					);
			},
			buttonSelector: ".technical-subscribe-button",
			submitOnEnter: true
		}
	);

	return {dispose: subscribeForm.dispose};

}

export function createNewsletterUnsubscribe(containerEl: HTMLElement) {
	const formEl = expressQuerySelector<HTMLFormElement>(containerEl, 'form', true);
	if (!isElementVisible(formEl)) return;
	const successMessageEl = expressQuerySelector<HTMLFormElement>(containerEl, '.technical-success-message', false);
	const errorMessageEl = expressQuerySelector<HTMLFormElement>(containerEl, '.technical-error-message', false);
	const emailEl = expressQuerySelector<HTMLInputElement>(formEl, '.technical-unsubscribe-mail', false);
	const checkBoxEl = expressQuerySelector<HTMLInputElement>(formEl, '#newsletter', false);
	const emptyGuid = '00000000-0000-0000-0000-000000000000';
	const appId = containerEl.dataset.appid || emptyGuid;
	const posId = containerEl.dataset.posid || emptyGuid;
	const email = getCookie('LastUsedEmail');

	// we prefill the email client side because of the html caching
	if (email) emailEl.value = email;

	const showSuccessMessage = () => {
		successMessageEl && expressRemoveClass(successMessageEl, "u-hide");
		errorMessageEl && expressAddClass(errorMessageEl, "u-hide");
		hide(formEl);
		expressAddClass(containerEl, "m-newsletter--success");
	};

	const showErrorMessage = () => {
		successMessageEl && expressAddClass(successMessageEl, "u-hide");
		errorMessageEl && expressRemoveClass(errorMessageEl, "u-hide");
		show(formEl);
	};

	emailEl && emailEl.setAttribute('required', 'required');
	if (checkBoxEl) checkBoxEl.setAttribute('required', 'required');

	const unsubscribeForm = initForm(
		formEl,
		{
			onSubmitForm: () => {
				const personIdFromQuery = getParameter("personid");
				if (emailEl && emailEl.value) {
					return getPersonIdAsync(emailEl.value, posId).then(res => {
						if (res !== emptyGuid) {
							if (personIdFromQuery && res.toLowerCase() !== personIdFromQuery.trim().toLowerCase()) {
								showSuccessMessage();
							} else {
								unSubscribeAsync(res, appId, posId, true, true)
									.then(
										() => {
											showSuccessMessage();
										}, (e) => {
											showErrorMessage();
										}
									);
							}
						} else {
							showSuccessMessage();
						}
					});
				} else if (personIdFromQuery) {
					return unSubscribeAsync(personIdFromQuery, appId, posId, true, true)
						.then(
							() => {
								showSuccessMessage();
							}, (e) => {
								showErrorMessage();
							}
						);
				} else {
					showErrorMessage();
				}
			},
			buttonSelector: ".technical-unsubscribe-button",
			submitOnEnter: true
		}
	);

	return {dispose: unsubscribeForm.dispose};
}
